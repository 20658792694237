<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 count-screen dashboard-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="count-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_count-dashboard-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("count", "dashboard", "dashboard") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        :hasFilters="true"
        :filters="filters"
        routeName="r_count-dashboard-search-filters"
      ></search>
    </template>
    <div v-if="filtersChanged" class="filters-changed-overlay"></div>
    <ul class="clebex-item-section calendar">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button
                class="clebex-item-range-cta"
                @click.prevent="openDateTimePicker('date')"
              >
                {{ displayDate(selectedDateFromParsed) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-calendar" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <button
                @click.prevent="openDateTimePicker('date')"
                class="clebex-item-range-cta"
              >
                {{ displayDate(selectedDateToParsed) }}
              </button>
            </li>
          </ul>
        </div>
      </li>

      <li
        class="clebex-item-section-item overflow-hidden calendar-expanded days"
      >
        <ul class="checkbox-day-list" v-if="dayLabels">
          <li
            class="checkbox-wrapper"
            v-for="(dayLabel, i) in dayLabels"
            :key="i"
          >
            <div class="checkbox tiny alt block">
              <input
                type="checkbox"
                :id="`day${i}`"
                :name="dayLabel"
                v-model="days[i]"
              />
              <label :for="`day${i}`" class="day-of-the-week">
                <icon icon="#cx-app1-checkmark" />
                {{ dayLabel }}</label
              >
            </div>
          </li>
        </ul>
      </li>
      <li class="clebex-item-section-item time">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button
                v-if="globalTimeFormat"
                class="clebex-item-range-cta"
                @click.prevent="openDateTimePicker('time-slot')"
              >
                {{ displayTime(selectedHourFrom) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-time" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <button
                v-if="globalTimeFormat"
                @click.prevent="openDateTimePicker('time-slot')"
                class="clebex-item-range-cta"
              >
                {{ displayTime(selectedHourTo) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <ul
      v-if="showDateTimePicker"
      class="clebex-item-section calendar calendar-picker"
    >
      <li class="clebex-item-section-item overflow-hidden calendar-expanded">
        <div class="clebex-item-content-wrapper">
          <pill-menu-tabs
            :nav-items="pillTabs"
            :preselected-tab="preselectedTab"
          >
            <template v-slot:date>
              <select-date-range
                :selectDateFn="selectDate"
                :show-date-picker="showDateTimePicker"
                :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                :selected-dates="[selectedDateFrom, selectedDateTo]"
              />
            </template>
            <template v-slot:time-slot>
              <select-time-picker-range
                :select-hour-fn="selectHour"
                :selected-hours="[selectedHourFrom, selectedHourTo]"
              />
            </template>
          </pill-menu-tabs>
        </div>
      </li>
    </ul>
    <ul
      v-if="filtersChanged"
      class="clebex-item-section search-refresh"
      :class="{ 'calendar-expanded': showDateTimePicker }"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("count", "dashboard", "refresh")
            }}</label>
          </div>
          <span @click="getData" class="follow-up-icon-item">
            <icon icon="#cx-foo1-refresh-28x28" />
          </span>
        </div>
      </li>
    </ul>
    <div class="gauges-wrapper">
      <div v-if="occupancy" class="single-gauge-wrapper">
        <div class="gauge-label">
          {{ displayLabelName("count", "dashboard", "occupancy") }}
        </div>
        <vue-gauge
          :refid="'type-unique-id-1'"
          :options="{
            needleValue: getPercentage('occupancy', true),
            arcDelimiters: [getPercentage('occupancy', true)],
            chartWidth: 175,
            arcColors: occupancy.capacity ? arcColors : disabledArcColors,
            needleColor: 'black',
            rangeLabel: ['', '']
          }"
        ></vue-gauge>
        <div class="gauge-values">
          {{ occupancy.average }} / {{ occupancy.capacity }} ({{
            getPercentage("occupancy")
          }}%)
        </div>
      </div>
      <div v-if="lastCount" class="single-gauge-wrapper">
        <div class="gauge-label">
          {{ displayLabelName("count", "dashboard", "last-count") }}
        </div>
        <vue-gauge
          :refid="'type-unique-id-2'"
          :options="{
            needleValue: getPercentage('lastCount', true),
            arcDelimiters: [getPercentage('lastCount', true)],
            chartWidth: 175,
            arcColors: lastCount.capacity ? arcColors : disabledArcColors,
            needleColor: 'black',
            rangeLabel: ['', '']
          }"
        ></vue-gauge>
        <div class="gauge-values">
          {{ lastCount.busy }} / {{ lastCount.capacity }} ({{
            getPercentage("lastCount")
          }}%)
        </div>
      </div>
      <div v-if="maxOccupancy" class="single-gauge-wrapper">
        <div class="gauge-label">
          {{ displayLabelName("count", "dashboard", "max-occupancy") }}
        </div>
        <vue-gauge
          :refid="'type-unique-id-3'"
          :options="{
            needleValue: getPercentage('maxOccupancy', true),
            arcDelimiters: [getPercentage('maxOccupancy', true)],
            chartWidth: 175,
            arcColors: maxOccupancy.capacity ? arcColors : disabledArcColors,
            needleColor: 'black',
            rangeLabel: ['', '']
          }"
        ></vue-gauge>
        <div class="gauge-values">
          {{ maxOccupancy.max }} / {{ maxOccupancy.capacity }} ({{
            getPercentage("maxOccupancy")
          }}%)
        </div>
      </div>
      <div v-if="minOccupancy" class="single-gauge-wrapper">
        <div class="gauge-label">
          {{ displayLabelName("count", "dashboard", "min-occupancy") }}
        </div>
        <vue-gauge
          :refid="'type-unique-id-4'"
          :options="{
            needleValue: getPercentage('minOccupancy', true),
            arcDelimiters: [getPercentage('minOccupancy', true)],
            chartWidth: 175,
            arcColors: minOccupancy.capacity ? arcColors : disabledArcColors,
            needleColor: 'black',
            rangeLabel: ['', '']
          }"
        ></vue-gauge>
        <div class="gauge-values">
          {{ minOccupancy.max }} / {{ minOccupancy.capacity }} ({{
            getPercentage("minOccupancy")
          }}%)
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <count-footer-menu></count-footer-menu>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import VueGauge from "vue-gauge";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";
import { mapState, mapGetters, mapActions } from "vuex";
import CountFooterMenu from "@/components/count/CountFooterMenu";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "CountDashboard",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    VueGauge,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    ),
    Search: defineAsyncComponent(() => import("@/components/search/Search")),
    CountFooterMenu
  },
  data() {
    return {
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedHourFrom: null,
      selectedHourTo: null,
      selectedDateFromParsed: null,
      selectedDateToParsed: null,
      search: false,
      pillTabs: ["date", "time-slot"],
      preselectedTab: null,
      showDateTimePicker: false,
      days: [true, true, true, true, true, false, false],
      dayLabels: null,
      arcColors: ["#e44f45", "#52ae43"],
      disabledArcColors: ["#a3a3a3", "#a3a3a3"],
      helpSlug: "count"
    };
  },
  created() {
    if (this.$route.matched.length > 1) {
      this.search = true;
    }
    this.setInitialValues();
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("searchFilters", [
      "filters",
      "filtersChanged",
      "filterPresets",
      "filtersClosed"
    ]),
    ...mapState("count", ["countData"]),
    selectedDateTimeFilter() {
      if (
        this.filters &&
        this.filters.length &&
        this.filters.find(filter => filter.slug === "dates" && filter.selected)
      ) {
        return this.filters.find(
          filter => filter.slug === "dates" && filter.selected
        );
      } else {
        return null;
      }
    },
    lastCount() {
      if (this.countData && this.countData.lastCount) {
        return this.countData.lastCount;
      } else {
        return null;
      }
    },
    maxOccupancy() {
      if (this.countData && this.countData.maxOccupancy) {
        return this.countData.maxOccupancy;
      } else {
        return null;
      }
    },
    minOccupancy() {
      if (this.countData && this.countData.minOccupancy) {
        return this.countData.minOccupancy;
      } else {
        return null;
      }
    },
    occupancy() {
      if (this.countData && this.countData.occupancy) {
        return this.countData.occupancy;
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions("count", ["getFilters", "calculate"]),
    ...mapActions("searchFilters", ["selectFilter", "resetFilters"]),
    modifySearchQuery(searchQuery) {
      this.$store.commit("search/setSearchQuery", searchQuery, {
        root: true
      });
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.submitSearch();
    },
    submitSearch() {
      this.calculate({ section: "dashboard" });
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].displayDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].displayDate;
      } else {
        this.selectedDateTo = null;
        this.selectedDateToParsed = null;
      }
      if (dates[0] && dates[1]) {
        this.setCustomDate(dates, false);
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
      if (hours[0] && hours[1]) {
        this.setCustomDate(false, hours);
      }
    },
    setCustomDate(dates, hours) {
      let customDateTimeFilter = this.filters.find(
        filter => filter.slug === "dates"
      );
      const defaultStart = this.selectedDateTimeFilter.date_start;
      const defaultEnd = this.selectedDateTimeFilter.date_end;
      if (!hours) {
        customDateTimeFilter.date_start = dates[0]
          ? `${DateTime.fromISO(
              dates[0].eventDate
            ).toISODate()}T${DateTime.fromISO(defaultStart).toFormat("HH:mm")}`
          : defaultStart;
        customDateTimeFilter.date_end = dates[1]
          ? `${DateTime.fromISO(
              dates[1].eventDate
            ).toISODate()}T${DateTime.fromISO(defaultEnd).toFormat("HH:mm")}`
          : defaultEnd;
      }
      if (!dates) {
        customDateTimeFilter.date_start = hours[0]
          ? `${DateTime.fromISO(defaultStart).toISODate()}T${DateTime.fromISO(
              hours[0]
            ).toFormat("HH:mm")}`
          : defaultStart;
        customDateTimeFilter.date_end = hours[1]
          ? `${DateTime.fromISO(defaultEnd).toISODate()}T${DateTime.fromISO(
              hours[1]
            ).toFormat("HH:mm")}`
          : defaultEnd;
      }
      customDateTimeFilter.selected = false;
      this.selectFilter({ filter: customDateTimeFilter, selected: true });
    },
    setInitialValues() {
      this.modifySearchQuery("");
      this.setDayLabels();
      this.calculate({ section: "dashboard", initial: true });
      this.getFilters("dashboard").then(() => {
        this.setInitialDateTime();
      });
    },
    setInitialDateTime() {
      const initialDateTimeFilter = this.filterPresets
        .find(preset => preset.selected)
        .filters.find(filter => filter.slug === "dates");
      this.setDateTime(initialDateTimeFilter);
    },
    setDateTime(filter) {
      this.selectedDateFrom = DateTime.fromISO(filter.date_start).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateTo = DateTime.fromISO(filter.date_end).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateFromParsed = DateTime.fromISO(
        filter.date_start
      ).toFormat("d MMMM yyyy");
      this.selectedDateToParsed = DateTime.fromISO(filter.date_end).toFormat(
        "d MMMM yyyy"
      );
      this.selectedHourFrom = DateTime.fromISO(filter.date_start).toFormat(
        "HH:mm"
      );
      this.selectedHourTo = DateTime.fromISO(filter.date_end).toFormat("HH:mm");
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    openDateTimePicker(tab) {
      if (this.preselectedTab !== tab) {
        this.preselectedTab = tab;
        this.showDateTimePicker = true;
      } else {
        this.showDateTimePicker = !this.showDateTimePicker;
      }
    },
    setDayLabels() {
      const locale = getLang();
      this.dayLabels = [
        DateTime.now()
          .set({ weekday: 1 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 2 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 3 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 4 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 5 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 6 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 7 })
          .toFormat("ccc", { locale: locale })
      ];
      this.setWeekdays();
    },
    setWeekdays() {
      let weekdays = [];
      this.days.forEach((day, i) => {
        if (day) {
          weekdays.push(
            DateTime.now()
              .set({ weekday: i + 1 })
              .toFormat("cccc")
              .toLowerCase()
          );
        }
      });
      this.$store.commit("count/setWeekdays", weekdays, {
        root: true
      });
    },
    toggleSearch() {
      this.search = !this.search;
    },
    getData() {
      this.calculate({ section: "dashboard" });
      this.$store.commit("searchFilters/setFiltersChanged", false, {
        root: true
      });
      this.showDateTimePicker = false;
    },
    getPercentage(type, max) {
      switch (type) {
        case "occupancy":
          if (!this.occupancy.capacity || !this.occupancy.average) {
            if (max) {
              return 0.01;
            }
            return 0;
          }
          if (
            (100 * this.occupancy.average) / this.occupancy.capacity > 99.99 &&
            max
          ) {
            return 99.99;
          }
          return (
            (100 * this.occupancy.average) /
            this.occupancy.capacity
          ).toFixed(2);
        case "lastCount":
          if (!this.lastCount.capacity || !this.lastCount.busy) {
            if (max) {
              return 0.01;
            }
            return 0;
          }
          if (
            (100 * this.lastCount.busy) / this.lastCount.capacity > 99.99 &&
            max
          ) {
            return 99.99;
          }
          return (
            (100 * this.lastCount.busy) /
            this.lastCount.capacity
          ).toFixed(2);
        case "maxOccupancy":
          if (!this.maxOccupancy.capacity || !this.maxOccupancy.max) {
            if (max) {
              return 0.01;
            }
            return 0;
          }
          if (
            (100 * this.maxOccupancy.max) / this.maxOccupancy.capacity >
              99.99 &&
            max
          ) {
            return 99.99;
          }
          return (
            (100 * this.maxOccupancy.max) /
            this.maxOccupancy.capacity
          ).toFixed(2);
        case "minOccupancy":
          if (!this.minOccupancy.capacity || !this.minOccupancy.max) {
            if (max) {
              return 0.01;
            }
            return 0;
          }
          if (
            (100 * this.minOccupancy.max) / this.minOccupancy.capacity >
              99.99 &&
            max
          ) {
            return 99.99;
          }
          return (
            (100 * this.minOccupancy.max) /
            this.minOccupancy.capacity
          ).toFixed(2);

        default:
          break;
      }
    }
  },
  beforeUnmount() {
    this.resetFilters();
  },
  watch: {
    selectedDateTimeFilter: {
      handler(val) {
        if (val) {
          this.setDateTime(val);
        }
      },
      immediate: true
    },
    days: {
      handler() {
        this.$store.commit("searchFilters/setFiltersChanged", true, {
          root: true
        });
        this.setWeekdays();
      },
      deep: true
    },
    filtersClosed: {
      handler(val) {
        if (val) {
          if (this.filtersChanged) {
            this.calculate({ section: "dashboard" });
            this.$store.commit("searchFilters/setFiltersChanged", false, {
              root: true
            });
          }
          this.$store.commit("searchFilters/setFiltersClosed", false, {
            root: true
          });
        }
      },
      immediate: true
    }
  }
};
</script>
